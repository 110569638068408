import React from 'react';

import desk from '../../assets/images/desk.jpg';
import PrePlanForm from '../../components/forms/prePlanForm';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const PrePlan = () => {
  return (
    <Layout hasHero={false} heroSize='30rem' heroImage={desk}>
      <SEO
        title='Information for Pre-Planning Arrangements'
        description='This form is to be filled out by the person making arrangements in advance and provides information for the death certificate.'
      />

      <div className='min-h-screen mb-6 flex flex-col items-start'>
        <div className='w-full mt-10 font-serif font-hairline'>
          <p className='text-4xl md:text-5xl text-green-700'>
            Let us help with paperwork.
          </p>
        </div>
        <div>
          <p className='text-xl py-2'>
            As I'm sure you can imagine, when a death occurs in our State, the
            laws mandate that several documents need to be completed. City of
            Oaks Cremation, LLC will complete those documents, obtain the
            Certified Death Certificates, notify the County and the Social
            Security Administration of the death by using the information the
            families provide.{' '}
          </p>
          <p className='text-xl py-2'>
            Providing this information in advance will help make the process
            easier when the time comes.
          </p>
          <p className='text-xl py-2'>
            Please <strong>only</strong> fill out this form if you've been
            instructed to do so by a member of our staff. Call with any
            questions 919-438-1649.
          </p>
        </div>{' '}
        <div
          className='w-full sm:w-3/4 lg:w-1/2 my-4 px-6 py-4 self-center'
          style={{
            boxShadow:
              '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)',
          }}
        >
          <p className='text-3xl text-center'>
            <strong> Pre-Planning Information </strong>
          </p>
          <PrePlanForm />
        </div>
      </div>
    </Layout>
  );
};

export default PrePlan;
